.App {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.div-style {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 10px;
  margin-top: 50px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* .video-part{
  height: 89vh;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
    flex-direction: row;
    width: 71%;

    margin: 10px auto;
    border-radius: 15px;
    align-content: center;
justify-content: center;
} */
.video-part-morethan-four {
  height: 89vh;
  display: flex;
  grid-template-columns: repeat(3, 1fr);
  flex-direction: row;
  width: 96%;
  margin: 10px auto;
  border-radius: 15px;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
}
.video-part-lessthan-four{
  display: flex;
    flex: 1;
    flex-direction: row;
    width: 100%;
    height: 89vh;
    margin: 0 auto;
    border-radius: 15px;
    align-items: center;
justify-content: center;
position: relative;
}
.video-part-four{
  width: 71%;
  height: auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-content: center;
  justify-content: center;
  margin: 10px auto;

  /* width: 77%; 
  height: 89vh;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
    align-content: center;
justify-content: center;
margin: 10px auto;*/
}
.video-part-five{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
    width: 71%;
    height: 89vh;
    margin: 0 auto;
    border-radius: 15px;
    align-content: center;
justify-content: center;
}
.video-part-five .remote-four:nth-child(4), .video-part-five .remote-four:nth-child(5) {
  margin-left: 52%;
}
.local-video-container-three {
  bottom: 80px;
  right: 20px;
  width: '33%';
  order: 3;
  position: relative;
  /* height: 350px; */
}
.remote-one-not-istpage {
  display: grid;
  gap: 16px;
  margin: 10px;
  width: 67%;
  height: 80vh;
  border-radius: 20px;
}
.remote-one {
  display: grid;
  gap: 16px;
  margin: 10px;
  width: 77%;
  height: 86vh;
  border-radius: 20px !important;
  overflow: hidden;
}
.remote-one-custom {
  display: grid;
  gap: 16px;
  margin: 10px;
  width: 77%;
  height: 86vh;
  border-radius: 20px !important;
  overflow: hidden;
  background: #FF8080;
}
.remote-two {
  display: grid;
  position: absolute;
  gap: 16px;
  height: 86vh;
  width: 60%;
  border-radius: 20px;
  margin: 10px;
  padding-left: 150px;
  padding-right: 150px;
}
.remote-two-custom {
  display: grid;
  position: absolute;
  gap: 16px;
  height: 86vh;
  width: 60%;
  border-radius: 20px;
  margin: 10px;
  padding-left: 150px;
  padding-right: 150px;
  background: #FF8080;
}
.remote-two-not-istpage {
  display: grid;
  gap: 16px;
  height: 39vh;
  border-radius: 20px;
  margin: 10px;
  padding-left: 150px;
  padding-right: 150px;
}
.remote-three {
  display: grid;
  gap: 16px;
  margin: 10px;
  /* width: 29vw !important;
  height: 38vh; */
  width: 31% !important;
  height: auto;
  border-radius: 20px !important;
  position: relative;
  overflow: hidden;
}
.remote-three-custom {
  display: grid;
  gap: 16px;
  margin: 10px;
  /* width: 29vw !important;
  height: 38vh; */
  width: 31% !important;
  height: 36vh;
  border-radius: 20px !important;
  position: relative;
  overflow: hidden;
  background: #FF8080;
}
.remote-four {
    display: grid;
    margin: 10px;
    border-radius: 20px !important;
    overflow: hidden;
    position: relative;
}
.remote-four-custom {
    display: grid;
    margin: 10px;
    border-radius: 20px !important;
    overflow: hidden;
    position: relative;
    background: #FF8080;
    height: 39vh;
    width: 96%;
}
.remote-morethan-four {
    display: grid;
    margin: 10px;
   width: 31% !important;
    height: auto;
    border-radius: 20px !important;
    overflow: hidden;
    position: relative;
}
.remote-morethan-four-custom {
    display: grid;
    margin: 10px;
   width: 31% !important;
    height: auto;
    border-radius: 20px !important;
    overflow: hidden;
    position: relative;
    background: #FF8080;
}
.remote-greaterthan-six {
  display: grid;
    margin: 6px;
    width: 24.8%;
    height: auto;
    border-radius: 20px !important;
    overflow: hidden;
    position: relative;
}
.remote-greaterthan-six-custom {
  display: grid;
    margin: 6px;
    width: 24.8%;
    height: auto;
    border-radius: 20px !important;
    overflow: hidden;
    position: relative;
    background: #FF8080;
}

.video-control-part{ 
  display: flex;
  align-items: center;
  justify-content: center;
  height: 8vh;
  padding-bottom: 10px;
}
.video-pos{
  /* position: relative; */
}
.video-main-div {
  display: flex;
    flex: 4.5 1;
    flex-direction: row;
    margin: 10px auto;
    border-radius: 14px;
    justify-content: center;
    overflow-y: auto;
    position: relative;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-start;

  }
  .local-video-container {
    position: absolute;
    bottom: 2.5%;
    right: 13%;
    width: 20%;
    height: auto;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px !important;
    overflow: hidden;
  }
  .local-video-container-custom {
    bottom: 2%;
    right: 13.5%;
    width: 100%;
    height: 24vh;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px !important;
    overflow: hidden;
    background: #FF8080;
  }
  .local-video-container-withmenu {
    position: absolute;
    bottom: 2.5%;
    right: 14%;
    width: 20%;
    height: auto;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px !important;
    overflow: hidden;
  }
  .local-video-container-withmenu-custom {
    position: absolute;
    bottom: 12%;
    right: 31%;
    width: 20%;
    height: auto;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px !important;
    overflow: hidden;
    background: #FF8080;
  }
  
.local-video-three {
  display: grid;
    gap: 16px;
    margin: 10px;
    width: 31% !important;
    height: auto;
    border-radius: 20px !important;
    overflow: hidden;
    position: relative;
}
.local-video-three-custom {
  display: grid;
    gap: 16px;
    margin: 10px;
    /* width: 31% !important; */
    height: 36vh;
    border-radius: 20px !important;
    overflow: hidden;
    position: relative;
    background: #FF8080;
}
.local-video-four {
  display: grid;
  margin: 10px;
  border-radius: 20px !important;
  overflow: hidden;
  position: relative;
}
.local-video-four-custom {
  display: grid;
  margin: 10px;
  border-radius: 20px !important;
  overflow: hidden;
  position: relative;
  background: #FF8080;
}
.local-video-morethan-four{
  display: grid;
  gap: 16px;
  margin: 10px;
  width: 31% !important;
  height: auto;
  border-radius: 20px !important;
  overflow: hidden;
  position: relative;
}
.local-video-morethan-four-custom{
  display: grid;
  gap: 16px;
  margin: 10px;
  width: 97% !important;
  height: 36vh;
  border-radius: 20px !important;
  overflow: hidden;
  position: relative;
  background: #FF8080;
}
.local-video-morethan-six-custom{
  display: grid;
  gap: 16px;
  margin: 10px;
  width: 97% !important;
  height: 26vh;
  border-radius: 20px !important;
  overflow: hidden;
  position: relative;
  background: #FF8080;
}
.local-video-morethan-six{
  display: grid;
  gap: 16px;
  margin: 10px;
  width: 23% !important;
  height: auto;
  border-radius: 20px !important;
  overflow: hidden;
  position: relative;
}
.local-video-morethan-six-custom{
  display: grid;
  gap: 16px;
  margin: 10px;
  width: 96% !important;
  height: 26vh;
  border-radius: 20px !important;
  overflow: hidden;
  position: relative;
  background: #FF8080;
}
.video-pos{
  position: relative;
}
  .loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border-left-color: #09f;
    animation: spin 1s linear infinite;
  }
  
  .inputstyle {
    width: 18%;
    height: 40px;
    border-radius: 4px;
    padding: 3px;
    padding-left: 10px;
    border: 1px solid #D9D9D9 !important;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
  }
  
  .txtstyle {
    font-family: 'Poppins';
    font-size: 18px;
    font-weight: 500;
    line-height: 10px;
    text-align: center;
    margin-top: 6%;
    color: #131313;
  }
  
.txtstyleStartCall {
  font-family: 'Poppins';
  font-size: 18px;
  line-height: 10px;
  font-weight: 600;
  text-align: center;
  margin-top: 10%;
  color: #131313;
}.maindiv-calling {
  display: 'flex';
  flex: 1;
  background-color: '#f0e7f5';
  flex-direction: 'column';
  width: 100%;
  height: 100vh;
  background: linear-gradient(102.11deg, #E3E2FF 4.8%, #FFEDE9 95.43%);
}.videopart-main-div {
  display: flex;
  flex: 1;
  background-color: rgb(36, 36, 36);
  flex-direction: row;
  width: 99%;
  height: 89vh;
  margin: 0.5%;
  border-radius: 15px;
  align-items: center;

}
.video-under-main-div {
  min-height: 86vh;
  display: flex;
  flex: 4.5;
  flex-direction: column;
  margin: 10px;
  border-radius: 14px;
  justify-content: center;
  overflow-y: auto;
  }
.header-style {
  font-family: 'Poppins';
font-size: 20px;
font-weight: 500;
text-align: left;
color: #191C1B;
margin-top: 10px;
}
.waiting-style {
  font-family: 'Poppins';
font-size: 14px;
font-weight: 500;
text-align: left;
color: #191C1B;
}
.list-txt-style {
  font-family: 'Poppins';
font-size: 14px;
font-weight: 400;
text-align: left;
color: #191C1B;
margin-left: 7px;
max-width: 75%;
}
.list-txt-style-host {
  font-family: 'Poppins';
font-size: 14px;
font-weight: 400;
text-align: left;
color: #191C1B;
margin-left: 7px;
}
.txt-admit {
  font-family: 'Poppins';
font-size: 14px;
font-weight: 600;
color: #0167FF;
cursor: pointer;
margin-left: 10px;
}
.txt-deny {
  font-family: 'Poppins';
font-size: 14px;
font-weight: 600;
color: #FF1D1D;
cursor: pointer;
}
.host-meeting-style {
  font-family: 'Poppins';
font-size: 10px;
font-weight: 500;
color: #191C1B;
text-align: left;
margin-bottom: 10px;
margin-left: 7px;
}
.short-name {
  position: absolute;
  bottom: 10px;
  right: 45%;
  color: white;
  padding: 5px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 20px;
  font-size: 12px;
  background: #00000040;
}









.mute-status {
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  object-fit: contain;
}
.risehand-status {
  position: absolute;
  top: 10px;
  right: 10px;
  /* background-color: rgba(0, 0, 0, 0.5); 
  padding: 5px 10px;
  border-radius: 5px;*/
  width: 40px;
  height: 40px;
  object-fit: contain;
}
.mute-status-fortwo {
  position: absolute;
  bottom: 10px;
  left: 10px;
  /* background-color: rgba(0, 0, 0, 0.5); 
  padding: 5px 10px;
  border-radius: 5px;*/
  width: 30px;
  height: 30px;
  object-fit: contain;
}
.lvideo-offcontainer-lessthanthree {
  width: 303px;
  min-height: 172px;
  gap: 0px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  font-size: 20px;
  font-weight: bold;
  border-radius: 20px;
  background: #FF8080;
  opacity: 0.9;
}
.lvideo-offcontainer-morethanthree {
  width: 100%;
  height: 100%;
  gap: 0px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 10px;
  font-family: 'Poppins';
  background: #FF8080;
  opacity: 0.9;
  font-size: 28px;
  line-height: 42px;
  text-align: center;
  font-weight: 600;
  min-height: 276px !important;
}
.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.dialog {
  background: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}

.startcall-box {
  width: 18.6%;
  border: 1px solid #D9D9D9;
  background: #F7F7F7;
  border-radius: 8px;
}

.cameraBox {
  background-color: #FFF;
  border-radius: 6px;
  padding-top: 15px;
  margin: 15px 40px;
  padding-bottom: 10px;
  box-shadow: 0px 4px 7px 0px #00000012;
}

.lobby-div {
  width: 92%;
  height: auto;
  border: 1px solid #D9D9D9;
  border-radius: 6px;
  margin-top: 10px;
  margin-bottom: 7px;
  padding: 10px;
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;

}
.hrstyle {
  margin: 10px 0;
  border: 0.5px solid #D9D9D9;

}

.txtstyleCameraoffon {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  color: #9C9C9C;
  margin-top: 40px;
}

.remote-video-container { 
  min-height: 276px !important;
  height: 100%;
  flex-direction: row;
}

.more-options {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 20px;
}

.options-menu {
  position: absolute;
  top: 30px;
  right: 10px;
  background: white;
  border: 1px solid #ccc;
  padding: 5px;
  z-index: 10;
}

.options-menu-item {
  padding: 5px;
  cursor: pointer;
}